import { PUBLIC_SENTRY_DSN, PUBLIC_JABBR_ENV } from '$env/static/public';
import * as Sentry from '@sentry/sveltekit';

const ignoreErrors = ['AbortError'];

if (PUBLIC_JABBR_ENV !== 'production') {
	// ignore any errors from the performance endpoint which only works in production
	ignoreErrors.push('https://feedback.jabbr.ai');
}

/**
 * Don't init Sentry in local env.
 * Avoid 'ReferenceError: __SVELTEKIT_DEV__ is not defined' because of slow client side vite plugin
 */
if (PUBLIC_JABBR_ENV !== 'local') {
	Sentry.init({
		dsn: PUBLIC_SENTRY_DSN,
		environment: PUBLIC_JABBR_ENV,

		// We recommend adjusting this value in production, or using tracesSampler
		// for finer control
		tracesSampleRate: 0.0,
		enableTracing: false,

		// Optional: Initialize Session Replay:
		integrations: [new Sentry.Replay()],
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,

		ignoreErrors: ignoreErrors
	});
}

const myErrorHandler = ({ error, event }) => {
	console.error('An error occurred on the client side:', error, event);
};

export const handleError = Sentry.handleErrorWithSentry(myErrorHandler);

// or alternatively, if you don't have a custom error handler:
// export const handleError = handleErrorWithSentry();
